<template>
  <layout v-load="loading">
    <template #header>
      <h1 class="title-1">Company / Organization structure</h1>
    </template>
    <div class="card m-0 p-0">
      <div class="card-body m-0 p-0">
        <div
            class="chart-container"
            style="height: calc(100vh - 120px); background-color: inherit; overflow: hidden"
        ></div>
      </div>
    </div>
  </layout>
</template>

<script>
import {OrgChart} from 'd3-org-chart';
import AvatarDefault from '@/assets/images/users/avatar-default.svg'

export default {
  name: 'OrgChartIndex',
  data() {
    return {
      chart: null,
      loading: false,
    }
  },
  computed: {
    employees () {
      return this.$store.getters['employees/employeeListForSelect']
    },
    tree() {
      return this.$store.state?.orgStruct?.tree?.map(el => {
        return {
          name: el.name,
          id: el.id,
          parentId: el.parent_id,
          positionName: el.position_name,
          profileUrl: el.profile_url,
          size: el.size,
          imageUrl: el.image_url,
          office: el.office,
          area: '',
        }
      }) ?? [];
    },
  },
  methods: {
    handleErrorToast(error) {
      const [title, uid] = error.split(':').map(el => el.replace(' ', ''))
      const employee = (title && uid) ? this.employees.find(el => el.uid === uid) : null
      this.$showErrorToast(`${employee ? `Missing employee ${employee.name}` : error}`)
      console.error(error)
    },
    constructChart() {
      try {
        this.chart = new OrgChart()
            .container('.chart-container')
            .data(this.tree)
            .nodeWidth(() => 250)
            .nodeHeight(() => 175)
            .initialZoom(0.7)
            .childrenMargin(() => 40)
            .compactMarginBetween(() => 15)
            .compactMarginPair(() => 80)
            .nodeContent(function (d) {
              return `
           <div style="padding-top:30px;background-color:none;margin-left:1px;height:${
                  d.height
              }px;border-radius:2px;overflow:visible">
           <div style="height:${
                  d.height - 32
              }px;padding-top:0px;background-color:white;border:1px solid lightgray; position: relative;">
           <img src="${d.data.imageUrl ?? AvatarDefault}" style="margin-top:-30px;margin-left:${d.width / 2 - 30}px;border-radius:100px;width:60px;height:60px;" />
           <div style="position: absolute; top: 15px; right: 5px; width: 85px; text-align: right" class="text-ellipsis">${
                  d.data?.office ?? '---'
              }</div>
           <div style="margin-top:-30px;background-color:#3AB6E3;height:10px;width:${d.width - 2}px;border-radius:1px"></div>
           <div style="padding:20px; padding-top:35px;text-align:center">
           <div style="color:#111672;font-size:16px;font-weight:bold"> ${d.data.name}</div>
           <div style="color:#404040;font-size:${d.data.positionName.split(',').length > 1 ? '12px' : '13px'};margin-top:4px" title="${d.data.positionName}"> ${d.data.positionName} </div></div>
           <div style="display:flex;justify-content:space-between;padding-left:15px;padding-right:15px;">
            <div > Manages:  ${d.data._directSubordinates} 👤</div>
            <div > Oversees: ${d.data._totalSubordinates} 👤</div>
          </div>
          </div>
          </div>
            `;
            })
            .expandAll()
            .render()
      } catch(e) {
        this.handleErrorToast(e.message)
      }
    },
  },
  async mounted() {
    this.loading = true
    await this.$store.dispatch('employees/getAllEmployees')
    await this.$store.dispatch('orgStruct/getOrgStructureTree');
    this.loading = false
    this.constructChart()
  },
}
</script>

